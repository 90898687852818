import "./Nav.css";
import { Link } from "react-router-dom";
import data from "../sdata.json";

export default function Nav() {
  return (
    <nav>
      <span className="nav-title">
        <Link to="/">G_Stickers</Link>
      </span>
      <ul>
      
        {data.map((item, index) => (
          <li key={item.name}>
            <Link to={`/${index + 1}`}>{item.name}</Link>
          </li> 
        ))}
        <li><a href="https://skdev.pages.dev/" >About</a></li>
      </ul>
    </nav>
  );
}