import "./Card.css";

interface ICard {
  name: string;
  aurl: string;
  url: string;
  aname:string;
  s_iurl:string;
}

export default function Card({ name, aurl, url, aname, s_iurl, }: ICard) {
  return (
    <div className="card">
      <img src={s_iurl} alt={name} />
      <div className="card-info">
        <div className="wallpaper-title">{name}</div>

        <div className="a-name"> <a href= {aurl}>{aname}</a></div>
        <div className="download-btn-container">

          <a className="download-btn" href={url} download>
            add to telegram
          </a>
        </div>
      </div>
    </div>
  );
}
