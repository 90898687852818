import "./Tab.css";
import { useParams } from "react-router-dom";
import Card from "./Card";

import data from "../sdata.json";

export default function Tab() {
  const params = useParams();
  console.log(params.id);
  const name = data[Number(params.id) - 1].name;
  const sdata = data[Number(params.id) - 1].sdata;



  console.log("TAB RENDERED");

  return (
    <div className="tab">
      <h1 className="tab-title">{name}</h1>
      <div className="card-container">
        {sdata.map((sdata) => (
          <Card name={sdata.name} url={sdata.url} aurl={sdata.aurl} aname={sdata.aname} s_iurl={sdata.s_iurl}  />
        ))}
      </div>
      
    </div> 
  );
}
