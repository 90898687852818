import "./Home.css";

const sdata = 
{
  name: "sticker name",
  surl:
    "https://wallpaper.skcloud1.workers.dev/0:/Desktop%20/Categories/Super%20Heros/2413612.jpg",
  url:
    "https://wallpaper.skcloud1.workers.dev/0:/Desktop%20/Categories/Super%20Heros/2413612.jpg",
};

export default function Home() {
  return (
    <div className="featured-wall">
      <div className="home-title">Featured Stickers</div>
      <div className="image-container">
        <img src={sdata.surl} alt="Featured Wallpaper" />
      </div>
      <div className="featured-dl-container">
        <a href={sdata.url} className="featured-dl">
        add to telegram
        </a>
      </div>
    </div>
  );
}
